<template>
    <ul class="p-0 mt-1 mb-0 list-none text-pink">
        <li
            v-for="err in error"
            :key="err"
            class="flex items-center gap-2 mt-2 mb-1 leading-tight"
            :id="err + '-error'"
        >
            <img class="" src="@/../img/warning.svg" />
            <span> {{ err }} </span>
        </li>
    </ul>
</template>

<script>
export default {
    props: ['name', 'error'],
};
</script>

<style>
</style>